import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${font.graphikCond};
  margin: 48px auto 48px auto;
  padding: 48px 10px;
  position: relative;
  width: 100%;

  &.sidebar-plea, &.europe {
    margin: 0px auto;
  }

  @media (${breakpoint.mdMin}) {
    &.sidebar-plea, &.europe {
      margin: 48px auto 0px auto;
    }
  }

  @media (${breakpoint.hybridMin}) {
    &.sidebar-plea {
      margin: 0px auto 48px auto;
    }
  }

  &::before {
    border-bottom: 2px solid;
    border-left: 2px solid;
    bottom: 5px;
    content: '';
    height: 40%;
    left: 15px;
    position: absolute;
    width: 90px;
  }

  &::after {
    border-right: 2px solid;
    border-top: 2px solid;
    content: '';
    height: 40%;
    position: absolute;
    right: 15px;
    top: 5px;
    width: 90px;
  }

  &.europe {
    &::after, &::before {
      display: none;
    }
    .content {
      padding: 0;
    }
    .button {
      display: flex;
      justify-content: center;
    }

    @media (${breakpoint.mdMin}) {
      .button {
        justify-content: flex-start;
      }
      &::after, &::before {
        display: block;
      }
    }
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 0 50px;
  z-index: 1;
`;

export const Label = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.56px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;

  a {
    text-transform: uppercase;
    font-weight: 500;
  }
`;
