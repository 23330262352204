import Link from 'components/Globals/Base/Link';
import { Plea as PleaProps } from 'interfaces/content/Media';
import Heading from 'styles/typography/Heading';
import RawHtml from 'utils/miscUtils/rawHtml';
import { Wrapper, Content, Label, ButtonWrapper } from './PleaStyles';

const Plea = ({ label, title, text, url, className }: PleaProps) => {
  const trackingData = {
    contentPlacementCD: 'hero+1',
    eventAction: title,
    eventCategory: 'navigation',
    eventLabel: text,
  };

  return (
    <Wrapper
      data-cy='plea-rr'
      className={className}
    >
      <Content className='content'>
        <Heading
          as='p'
          $fontFamily='graphikCond'
          $size={{ default: 'level2' }}
        >
          {title}
        </Heading>
        <Label>{RawHtml({ html: label })}</Label>
        <ButtonWrapper className='button'>
          <Link
            trackingData={trackingData}
            href={url}
            type='primaryButton'
            hasRightArrow={false}
          >
            {text}
          </Link>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default Plea;
