import styled from 'styled-components';

const HiddenHeaderTags = styled.div`
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export default HiddenHeaderTags;
