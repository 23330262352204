import NextArrow from 'components/Arrows/NextArrow';
import PrevArrow from 'components/Arrows/PrevArrow';
import useBreakpoint from 'hooks/useBreakpoint';
import { ReactNode, useState } from 'react';
import Slick from 'react-slick';
import { ArrowWrapper, SliderWrapper, Wrapper, DesktopSliderWrapper } from './RowStyles';

export interface RowProps {
  children?: ReactNode[];
  carousel?: boolean;
  columns?: number;
  dataCy?: string;
}

const tabletCarouselSettings = {
  accessibility: true,
  dots: false,
  infinite: false,
  nextArrow: (
    <ArrowWrapper>
      <NextArrow />
    </ArrowWrapper>
  ),
  prevArrow: (
    <ArrowWrapper>
      <PrevArrow />
    </ArrowWrapper>
  ),
  rows: 1,
  slidesToScroll: 2,
  slidesToShow: 3.25,
  swipeToSlide: true,
};

const desktopCarouselSettings = {
  accessibility: true,
  dots: false,
  infinite: false,
  nextArrow: (
    <ArrowWrapper>
      <NextArrow />
    </ArrowWrapper>
  ),
  prevArrow: (
    <ArrowWrapper>
      <PrevArrow />
    </ArrowWrapper>
  ),
  rows: 1,
  slidesToScroll: 2,
  slidesToShow: 4,
};

type Arrow = 'prev' | 'next' | 'both';

const Row = ({ children, carousel, columns, dataCy }: RowProps) => {
  const [arrowState, setArrowState] = useState<Arrow>('next');
  const mdMin = useBreakpoint('mdMin');
  const lgMin = useBreakpoint('lgMin');

  const hidden = !children || children.length < 1 ? 'hidden' : '';
  const one = children && children.length === 1 ? 'one' : '';
  const two = children && children.length === 2 ? 'two' : '';
  const three = children && children.length === 3 ? 'three' : '';
  const four = children && children.length === 4 ? 'four' : '';
  const more = children && children.length >= 5 ? 'more' : '';

  const handleChange = (_i: number, index: number) => {
    const first = index === 0;
    const last = children && index + 3.25 === children.length;
    if (first && last) {
      setArrowState('both');
    } else if (first) {
      setArrowState('next');
    } else if (last) {
      setArrowState('prev');
    }
  };

  if (carousel && children && children.length > 3 && lgMin) {
    return (
      <DesktopSliderWrapper>
        <Slick {...desktopCarouselSettings}>{children}</Slick>
      </DesktopSliderWrapper>
    );
  }

  if (carousel && children && children.length > 3 && mdMin) {
    return (
      <SliderWrapper className={arrowState}>
        <Slick
          {...tabletCarouselSettings}
          beforeChange={handleChange}
        >
          {children}
        </Slick>
      </SliderWrapper>
    );
  }

  return (
    <Wrapper
      data-cy={dataCy}
      className={`${columns ? `col-${columns}` : `${hidden} ${one} ${two} ${three} ${four} ${more}`}`}
    >
      {children}
    </Wrapper>
  );
};

export default Row;
