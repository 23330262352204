import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import { AdsCenteredDiv, InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const ProductHomePageInStream: React.FC<BaseAdConfig> = ({ id }: BaseAdConfig) => (
  <AdsCenteredDiv>
    <InStreamAd className='simple-homepage'>
      <AdSlotToggle
        placementName='InContent'
        index={id + 1}
        slotId={`InStream${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default ProductHomePageInStream;
