import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';
import { Theme, getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div<{ theme: Theme }>`
  align-content: center;
  display: block;
  flex-direction: row;
  font-family: ${font.graphikCompact};
  height: auto;
  max-width: none;
  padding: 24px 16px 0;
  position: relative;
  top: -24px;
  width: 100%;
  z-index: 2;
  position: relative;

  ${imageAndTitleLinkHover}

  &.first-primary {
    margin: 48px 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    background: ${getColor('surfaceBrand')};
    height: 100%;
    z-index: -1;
  }

  @media (${breakpoint.mdMin}) {
    padding: 24px 24px 0;
    margin: 24px 0 0;

    &.first-primary {
      margin-top: 0;
    }
  }

  @media (${breakpoint.lgMin}) {
    display: flex;
    padding: 48px 48px 0;

    &.first-primary {
      margin-top: 24px;
    }
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
  and  (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    display: flex;
    padding:  24px 24px;
    margin-bottom: 24px;
    &.first-primary {
      margin-top: 24px;
    }
  }

  @media (${breakpoint.xlMin}) {
    margin: 48px 0 0;
    
    &::after {
      height: 384px;
    }

    &.first-primary {
      margin-top: 48px;
    }
  }
`;

export const ImageWrapper = styled.div`
  flex: 1 0 49%;
  max-width: 648px;
  width: 100%;
  position: relative;

  @media (${breakpoint.mdMin}) {
    flex: 1 0 100%;
    max-width: 720px;
  }

  @media (${breakpoint.lgMin}) {
    flex: 1 0 49%;
    max-width: 648px;
    margin-top: 48px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    flex: 1 0 49%;
    max-width: 648px;
    margin-top: 48px;
  }

  @media (${breakpoint.xlMin}) {
    margin-top: -48px;
  }
`;

export const TextWrapper = styled.div`
  flex: 1 0 100%;
  margin-top: 24px;
  max-width: 100%;

  @media (${breakpoint.lgMin}) {
    flex: 1 0 49%;
    margin-top: 0;
    max-width: 49%;
    padding-left: calc(32px + 1vw);
    padding-top: 32px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    flex: 1 0 49%;
    margin-top: 0;
    max-width: 49%;
    padding-left: calc(32px + 1vw);
    padding-top: 32px;
  }

  @media (${breakpoint.xlMin}) {
    padding-top: 0;
  }
`;

export const Section = styled.span`
  display: block;
  text-decoration: none;
  font-family: ${font.graphikCond};
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;

  > a {
    position: relative;
    z-index: 2;
  }
`;

export const Title = styled(Heading)`
  text-decoration: none;
  margin-bottom: 10px;
  overflow: hidden;
  color: inherit;

  > a::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
  }
`;

export const Excerpt = styled.p`
  font-family: ${font.graphikCond};
  font-size: 24px;
  font-weight: 300;
  line-height: 26.5px;
  margin-top: 16px;

  @media (${breakpoint.lgMin}) {
    font-size: 26px;
    line-height: 28.5px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125) ) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    font-size: 20px;
    line-height: 28.5px;
  }
`;

export const ByLineListWrapper = styled.div`
  margin-top: 16px;
`;

export const Publishdate = styled.time<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-family: ${font.graphikCond};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.17px;
  line-height: 21px;
`;
